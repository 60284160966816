import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './HomeWidget.sass';

function HomeWidget(props) {
    // Declare randoColor as a state variable
    const [randoColor] = useState(() => {
        let randoIndex = Math.floor(Math.random() * props.color.length);
        return props.color[randoIndex];
    });

    const [loadedImage, setLoadedImage] = useState(null);


    // Preloading image using useEffect
    useEffect(() => {
        const imageDirectory = props.productType === 'lsv' ? '/lsv/' : '/scooters/';
        const sanitize = props.name
            .split('-')
            .map(group => group.trim().replace(/\s/g, '-'))
            .join('-')
            .toLowerCase();
        const fileExtension = props.productType === 'lsv' ? '.png' : '.jpg';
        const imgSrc = imageDirectory + sanitize + "/" + randoColor + "/2" + fileExtension;


        console.log(imgSrc); // Verify your file path is correct
        if (props.productType === 'lsv') console.log('LSV imgSrc:', imgSrc); // Add this line


        let img = new Image();
            img.onload = () => {
                console.log(`Image loaded: ${imgSrc}`); // Adding this line will confirm if the onload function is called
                setLoadedImage(imgSrc);
            };
            img.src = imgSrc;

    }, [props.name, randoColor, props.productType]); // randoColor used in the dependency array

    return (
        <div className={'listing ' + props.name.replaceAll(' ', '-').toLowerCase()}  key={props.name}>
            {/* Create dynamic, clickable link that corresponds to routing on app.js */}
            <Link to={props.link}>
                {/* Show dynamic image of scooter - loop through available colors for each scooter and randomly display colors per scooter */}
                {loadedImage && <img className="listingImg" alt={props.name} src={loadedImage} />}
            </Link>
            <Link className="scootLink" to={props.link}>
                <h1>{props.name}</h1></Link>
        </div>
    );
}

export default HomeWidget;

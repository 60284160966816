import './NavHeader.css';
import { Link } from 'react-router-dom';
import React from "react";
import { BUSINESS_HOURS } from './constants';

function NavHeader() {

    return (
        <div className="navHeader">
            <div className="flexit">
                <Link to="/">
                    <h1 className="test">
                        <span className="c1">Omaha</span>
                        <span className="c2">Scooters</span>
                        <span className="c1">.</span>
                        <span className="c1">com</span>
                    </h1>
                </Link>
                <div className="rightNav">
                    <a className="cartsLink" href="https://omahagolfcarts.com/" target="_blank">
                        <h1>Golf Carts</h1>
                    </a>
                    <a className="cartsLink" href="https://omahagolfcarts.com/" target="_blank">
                        <h1>Low Speed Vehicles (LSVs)</h1>
                    </a>
                    <Link className="scootersLink" to="/">
                        <h1>Scooters</h1>
                    </Link>
                    <Link className="mopeds" to="/mopeds">
                        <h1>Mopeds</h1>
                    </Link>
                    <Link className="about" to="/about">
                        <h1>About</h1>
                    </Link>
                </div>
            </div>
            <div className="detailWrapper">
                <h2 className="deets">1210 Royal Dr. Papillion, NE 68046 |  Call / Text: <a href="tel:4022085111">402-208-5111</a> </h2>
                <h3>{BUSINESS_HOURS}</h3>
            </div>



        </div>

    );
}

export default NavHeader;

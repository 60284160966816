import React, { useState, useEffect } from 'react';
import inventory from "./lsv.json";
import common from "./lsv-common.json"
import Contactphones from "../contactphones";
import { useParams } from "react-router-dom";
import "../scooter.sass"





function Lsv() {
    let { lsvname } = useParams();
    console.log(lsvname);
    let lsvmatch = inventory.find((lsv) => lsv.routing === lsvname);
    console.log(common);

    function formatName() {
        const nameParts = lsvmatch.name.split(' ');

        if (nameParts.length >= 3) {
            const productName = nameParts.slice(0, 2).join(' ');
            const productType = nameParts.slice(2).join(' ');

            return (
                <>
                    {productName}<br />{productType}
                </>
            );
        }

        return lsvmatch.name;
    }

    function formatDescription() {
        const descriptionParts = lsvmatch.description.split('\n\n');
        return descriptionParts.map((part, index) =>
            <p key={index}>{part}</p>
        );
    }


    // Move randoColor to a state variable, this used to be scooterColor
    const [randoColor] = useState(() => {
        let randoLimit = lsvmatch.colors.length;
        return lsvmatch.colors[Math.floor(Math.random() * randoLimit)];
    });

    const [selectedColor, setSelectedColor] = useState(randoColor);

    const [loadedImages, setLoadedImages] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null });

    useEffect(() => {
        // Reset the loadedImages
        setLoadedImages({});

        lsvmatch.colors.forEach(color => {
            for (let i = 1; i <= 7; i++) {
                const imgSrc = "/lsv/" + lsvname + "/" + color + "/" + i + ".png";
                const newImage = new Image();
                newImage.src = imgSrc; // Preload image
                newImage.onerror = () => {
                    console.log("Failed to load image: " + imgSrc);
                };
                newImage.onload = () => {
                    setLoadedImages(prevImages => ({
                        ...prevImages,
                        [color]: {
                            ...(prevImages[color] || {}), // This line ensures you're not overwriting the images of this color that might have already loaded
                            [i]: imgSrc
                        }
                    }));
                };
            }
        });

    }, [lsvname, lsvmatch.colors]);

    let scooterPics = (
        <div className="gallery">
            {[1, 2, 3, 4, 5, 6, 7].map(idx =>
                loadedImages[selectedColor] && loadedImages[selectedColor][idx] &&
                <img key={idx} src={loadedImages[selectedColor][idx]} alt=""/>
            )}
        </div>
    );

    let price = lsvmatch.price.toLocaleString('en-US', {
        value: 'USD',
        minimumFractionDigits: 2
    });

    return (
        <div className="scooterPage">

            <div className="mobileHeader">
                <div className="flex">
                    <div>
                        <h1>{formatName()}</h1>
                    </div>
                    <div className="rightTxt">
                        <h1>$ {price}</h1>
                        <h1>{lsvmatch.stock}</h1>
                    </div>
                </div>
                <div className="scooterColors">
                    {lsvmatch.colors.map(color => (
                        <p className={'scooter-color swatch ' + color}
                           onClick={() => {
                               setSelectedColor(color);
                           }}
                           key={color}></p>
                    ))}
                </div>
            </div>


            <div id="dynamicGallery" className="galleryWrap">

                {scooterPics}

            </div>

            <div className="details">

                <div className="detailHeader">
                    <div className="flex">
                        <div>
                            <h1>{formatName()}</h1>
                        </div>
                        <div className="rightTxt">
                            <h1>$ {price}</h1>
                            <h1>{lsvmatch.stock}</h1>
                        </div>
                    </div>
                    <div className="scooterColors">
                        {lsvmatch.colors.map(color => (
                            <p className={'scooter-color swatch ' + color}
                               onClick={() => {
                                   setSelectedColor(color);
                               }}
                               key={color}></p>
                        ))}
                    </div>
                </div>

                <div className="interest">
                    <h1><em>Interested in this Scooter?</em></h1>
                    <Contactphones></Contactphones>
                </div>

                <div className="description">
                    {formatDescription()}
                </div>

                <div className="productDetails">
                    <h2>Low Speed Electric Vehicle Details</h2>
                    <p><span>Max Speed:</span> <span>{common.power.max_speed}</span></p>
                    <p><span>Length:</span> <span>{lsvmatch.length}"</span></p>
                    <p><span>Height:</span> <span>{lsvmatch.height}"</span></p>
                    <p><span>Tire Size:</span> <span>{lsvmatch.tiresize}"</span></p>
                    <p><span>Minimum Ground Clearance (Ln):</span> <span>{common.functionality.ground_clearance}"</span>
                    </p>
                    <p><span>Controller:</span> <span>{common.power.controller}</span></p>
                    <p><span>Battery:</span> <span>{common.power.battery}</span></p>
                    <p><span>Motor:</span> <span>{common.power.motor}</span></p>
                    <p><span>Charger:</span> <span>{common.power.charger}</span></p>
                    <p><span>Steering:</span> <span>{common.functionality.steering_system}</span></p>
                    <p><span>Brakes:</span> <span>{common.functionality.brakes}</span></p>
                    <p><span>Parking Break:</span> <span>{common.functionality.parking_brake}</span></p>
                    <p><span>Drive:</span> <span>{common.functionality.drive}</span></p>
                </div>

                <div className="productDetails">
                    <h2>Street Legal</h2>
                    <p><span>Certification:</span> <span>{common.street_legal.certification}</span></p>
                    <p><span>Seatbelts:</span> <span>{common.street_legal.seatbelts}</span></p>
                    <p><span>Front Windshield:</span> <span>{common.street_legal.front_windshield}</span></p>
                    <p><span>Mirrors:</span> <span>{common.street_legal.mirrors}</span></p>
                    <p><span>Rearview Mirror:</span> <span>{common.street_legal.rearview_mirror}</span></p>
                    <p><span>Turn Signals:</span> <span>{common.street_legal.turn_signals}</span></p>
                    <p><span>Horn and Buzzer:</span> <span>{common.street_legal.horn_and_buzzer}</span></p>
                    <p><span>Reflectors:</span> <span>{common.street_legal.reflectors}</span></p>
                    <p><span>VIN:</span> <span>{common.street_legal.vin}</span></p>
                </div>


            </div>


        </div>
    );

}

export default Lsv;


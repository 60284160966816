import React, {Component} from 'react';
import Contactphones from "./contactphones";
import { BUSINESS_HOURS } from './constants';

class About extends Component {
    render() {
        return (
            <div className="aboutPage">
                <div>
                <h1> About Omaha Scooters</h1>
                <Contactphones></Contactphones>
                    <hr/>
                    <div>
                        <h2>1210 Royal Dr. Papillion, NE 68046</h2>
                    </div>
                    <hr/>
                    <div>
                        <h2>Hours</h2>
                        <h2>{BUSINESS_HOURS}</h2>
                        <h2>Call or text to make an appointment</h2>
                    </div>
                    <hr/>
                </div>

                {/* Google Map */}
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.2111726762696!2d-96.04459718462057!3d41.17356107928437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87938b17577ffc2d%3A0x918ce6243cf68f39!2s84th%20Street%20Motors%2C%20Inc.!5e0!3m2!1sen!2sus!4v1653960487858!5m2!1sen!2sus"
                    width="100%" height="450" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" title="84th Street Motors Map"></iframe>

            </div>
        );
    }
}

export default About;
